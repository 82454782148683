import React, { useState } from "react";
import { FloatButton, Menu } from "antd";
import { GiftOutlined, FlagOutlined, MenuOutlined, HomeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    {
        label: <a href="/">Home</a>,
        key: 'home',
        icon: <HomeOutlined />,
    },
    {
        label: <a href="/races">Races</a>,
        key: 'races',
        icon: <FlagOutlined />,
    },
    {
        label: <a href="/sweepstakes">Sweepstakes</a>,
        key: 'sweepstakes',
        icon: <GiftOutlined />,
    },
]

const Navbar = () => {

    const [current, setCurrent] = useState('mail');
    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
    
    return <Menu className="nav_container" onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
};

export default Navbar;
